import MyLocationIcon from '@mui/icons-material/MyLocation'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { makeStyles } from '@mui/styles'
import { visuallyHidden } from '@mui/utils'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { formatDate } from '../../../utils/functions/doformsDateUtil'
import { getDeviceName, getFormName } from '../../../utils/functions/helpers'
import {
	convertFeetToMeters,
	formatDistance,
	formatElapsed,
	formatSpeed,
	formatSpeedToKilometersPerHour,
} from '../../../utils/functions/mapUtils'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { loadForm } from '../dataServices'
import { createMapGridIcon, formMapGridHeadCell, formMapGridHeadCell4Report } from './mapHelpers'
import { getRecordName } from './mapService'

const useStyles = makeStyles(() => ({
	mapGrid: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		minHeight: '100px',
		'& > .MuiPaper-root': {
			display: 'flex',
			flex: '1 1 auto',
			width: '100%',
			overflow: 'auto',
			marginBottom: 0,
		},
	},
}))

function descendingComparator(a, b, orderBy, orderDataType) {
	let left = get(a, orderBy) || 0
	let right = get(b, orderBy) || 0
	if (orderDataType === 'datetime') {
		left = left !== 0 ? moment(left).valueOf() : 0
		right = right !== 0 ? moment(right).valueOf() : 0
	}
	if (right < left) {
		return -1
	}
	if (left < right) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy, orderDataType) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy, orderDataType)
		: (a, b) => -descendingComparator(a, b, orderBy, orderDataType)
}

function isTimesheetChecked(reportState) {
	if (!reportState) {
		return false
	}
	const timesheet = reportState.filter((state) => state.name === 'timesheet')
	if (timesheet.length > 0 && timesheet[0].checked) {
		return true
	}
	return false
}

function EnhancedTableHead(props) {
	const {
		order,
		orderBy,
		onRequestSort,
		selectedOption,
		detailState,
		reportState,
		deviceSelected,
		t,
		module,
	} = props
	const createSortHandler = (property, dataType) => (event) => {
		onRequestSort(event, property, dataType)
	}

	let headCells
	if (selectedOption === 'report') {
		headCells = formMapGridHeadCell4Report(isTimesheetChecked(reportState), deviceSelected, t)
	} else {
		headCells = formMapGridHeadCell(detailState, deviceSelected, t, module)
	}
	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<MyLocationIcon fontSize="small" opacity={0} />
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding="normal"
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ cursor: headCell.sortable ? 'pointer' : 'default' }}
					>
						<TableSortLabel
							active={headCell.sortable && orderBy === headCell.id}
							hideSortIcon={!headCell.sortable}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={
								headCell.sortable
									? createSortHandler(headCell.id, headCell.format || 'string')
									: undefined
							}
							style={{ cursor: headCell.sortable ? 'pointer' : 'default' }}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc'
										? t('common:misc.sortedDescending')
										: t('common:misc.sortedAscending')}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
}

const DoformsMapGrid = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()

	const {
		environment,
		selectedPlace,
		mapRecords,
		onHandledSelected,
		onHandledNewMapItemFetched,
		selectedOption,
		detailState,
		reportState,
		deviceSelected,
		module,
		title,
	} = props

	const [order, setOrder] = useState('desc')
	const [orderBy, setOrderBy] = useState('time')
	const [orderDataType, setOrderDataType] = useState('datetime')

	const isMetricUser = environment?.userCurrent?.units === 'metric'

	const dispatch = useDispatch()

	const formatTimeHMA = (dateValue) => {
		return formatDate(
			dateValue,
			environment.userCurrent.time.timezone.isDst
				? environment.userCurrent.time.timezone.offset - 60
				: environment.userCurrent.time.timezone.offset,
			'h:mm a'
		)
	}

	const formatTimeHMAWithOffset = (dateValue) => {
		// user offset includes DST
		const offset = environment?.userCurrent?.time?.timezone?.offset ?? null

		// dateValue is UTC timezone
		let m = moment.utc(dateValue)
		let formatUse = 'h:mm a'
		if (offset != null) {
			m.utcOffset(offset)
		}
		return m.isValid() ? m.format(formatUse) : ''
	}

	const handleRequestSort = (event, property, dataType) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
		setOrderDataType(dataType)
	}

	const handleSelected = (event, mapItem) => {
		if (isEmpty(mapItem)) return
		switch (mapItem.type) {
			case 'trip_report':
				onHandledSelected(mapItem, false)
				break
			default:
				onHandledSelected(mapItem, true)
		}
		event.stopPropagation()
	}

	const isShowFormNameEmpty = (record) => {
		if (!record) return true
		switch (record.type) {
			// dispatch
			case 'dispatch':
				if (!module.dispatchRecord4Map) {
					return true
				}
				return false
			case 'event':
				return false
			// form
			case 'location':
				return false
			// tracking
			case 'track':
				return true
			// geofence
			case 'geofence':
				return true
			// timesheets
			case 'timesheets':
				return true
			default:
				return true
		}
	}

	const isShowRecordNameEmpty = (record) => {
		if (!record) return true
		switch (record.type) {
			// dispatch
			case 'dispatch':
				if (!module.dispatchRecord4Map) {
					return true
				}
				return false
			case 'event':
				return false
			// form
			case 'location':
				return false
			// tracking
			case 'track':
				return true
			// geofence
			case 'geofence':
				return false
			// timesheets
			case 'timesheets':
				return true
			default:
				return true
		}
	}

	const handleGetFormName = (record) => {
		if (!record.formKey) {
			record.form = ''
			onHandledNewMapItemFetched(record)
			return
		}

		let formName = getFormName(record.formKey, environment.forms)
		if (formName && !isEmpty(formName)) {
			record.form = formName
			onHandledNewMapItemFetched(record)
			return
		}
		let value = record
		value.formLoading = true
		onHandledNewMapItemFetched(value)
		loadForm(record.formKey, environment.apiToken)
			.then((response) => {
				value.form = response.data.name
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: [response.data],
				})
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				value.formLoading = false
				onHandledNewMapItemFetched(value)
			})
	}

	const handleGetRecordName = (record) => {
		let value = record
		value.recordLoading = true
		onHandledNewMapItemFetched(value)
		getRecordName(
			record.type == 'dispatch' ? record.dispatchKey : record.recordKey,
			environment.apiToken
		)
			.then((res) => {
				value.recordName = res.data.name
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				value.recordLoading = false
				onHandledNewMapItemFetched(value)
			})
	}

	const handleGetAddress = (record) => {
		if (isEmpty(record.position)) return
		const location = new window.google.maps.LatLng(
			record.position.latitude,
			record.position.longitude
		)
		const geocoder = new window.google.maps.Geocoder()
		let value = record
		value.addressLoading = true
		onHandledNewMapItemFetched(value)

		geocoder.geocode({ location: location }, (res) => {
			if (!res.length) {
				value.address = '*No address found'
				value.addressLoading = false
			} else {
				value.address = res[0].formatted_address
				value.addressLoading = false
			}
			onHandledNewMapItemFetched(value)
		})
	}

	const _formatDistance = (increment, total) => {
		if (!total) total = 0
		let formattedIncrement = formatDistance(increment || 0, isMetricUser)
		let formattedTotal = formatDistance(total, isMetricUser)
		return '+ ' + formattedIncrement + ' = ' + formattedTotal
	}

	let hasDescription = false
	let hasTraveled = false
	let hasDuration = false
	let hasSpeed = false
	let hasDistance = false
	let hasForm = false
	let hasRecord = false
	let hasTeamDevice4Report = false

	if (deviceSelected && !deviceSelected.key) {
		hasTeamDevice4Report = true
	}
	if (detailState && detailState.length > 0) {
		for (let i = 0; i < detailState.length; i++) {
			let item = detailState[i]
			if (item.checked) {
				switch (item.name) {
					case 'devices':
						hasDuration = true
						hasSpeed = true
						break
					case 'tracking':
						hasTraveled = true
						hasDuration = true
						hasSpeed = true
						hasDistance = true
						break
					case 'geofence':
						hasDescription = true
						hasDuration = true
						hasRecord = true
						break
					case 'dispatch':
						hasForm = true
						hasRecord = true
						break
					case 'timesheet':
						hasDescription = true
						hasDuration = true
						break
					case 'form':
						hasDescription = true
						hasForm = true
						hasRecord = true
						break
					default:
						break
				}
			}
		}
	}

	return (
		<div className={classes.mapGrid}>
			<Paper>
				<TableContainer sx={{ cursor: 'pointer', overflow: 'unset' }}>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							selectedOption={selectedOption}
							detailState={detailState}
							reportState={reportState}
							deviceSelected={deviceSelected}
							t={t}
							module={module}
						/>
						<TableBody>
							{mapRecords
								.slice()
								.sort(getComparator(order, orderBy, orderDataType))
								.map((record) => {
									const formatSpeedNumber = record.velocity
										? isMetricUser
											? formatSpeedToKilometersPerHour(record.velocity.speed)
											: formatSpeed(record.velocity.speed)
										: ''
									return (
										<TableRow
											hover
											onClick={(e) => handleSelected(e, record)}
											key={record.key}
											selected={selectedPlace ? record.key === selectedPlace.key : false}
											style={{ height: '36px' }}
										>
											<TableCell padding="checkbox">{createMapGridIcon(record)}</TableCell>
											{!module.dispatchRecord4Map &&
												(selectedOption === 'detail' || hasTeamDevice4Report) && (
													<TableCell align="left">
														{record.type !== 'device'
															? record.device
															: getDeviceName(record.key, environment.devices)}
													</TableCell>
												)}
											{selectedOption === 'detail' && (
												<TableCell align="left">{formatTimeHMAWithOffset(record.date)}</TableCell>
											)}
											<TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
												{record.status}
											</TableCell>
											{selectedOption === 'report' && (
												<TableCell align="left">{formatTimeHMA(record.beginDate)}</TableCell>
											)}
											{selectedOption === 'report' && (
												<TableCell align="left">{formatTimeHMA(record.endDate)}</TableCell>
											)}
											{selectedOption === 'report' && (
												<TableCell align="left">{formatElapsed(record.duration)}</TableCell>
											)}
											{selectedOption === 'report' && (
												<TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
													{formatDistance(record.distance || 0)}
												</TableCell>
											)}
											{isTimesheetChecked(reportState) && selectedOption === 'report' && (
												<TableCell align="left">
													{['timesheet', 'timesheet_report'].includes(record.type)
														? record.beginDescription
														: ''}
												</TableCell>
											)}
											{isTimesheetChecked(reportState) && selectedOption === 'report' && (
												<TableCell align="left">
													{['timesheet', 'timesheet_report'].includes(record.type)
														? record.endDescription
														: ''}
												</TableCell>
											)}
											{selectedOption === 'detail' &&
												!module.dispatchRecord4Map &&
												hasDescription && <TableCell align="left">{record.description}</TableCell>}
											{selectedOption === 'detail' && hasTraveled && (
												<TableCell align="left">{formatElapsed(record.elapsed)}</TableCell>
											)}
											{selectedOption === 'detail' && hasDuration && (
												<TableCell align="left">{formatElapsed(record.duration)}</TableCell>
											)}
											{selectedOption === 'detail' && hasSpeed && (
												<TableCell align="left">{formatSpeedNumber || ''}</TableCell>
											)}
											{selectedOption === 'detail' && hasDistance && (
												<TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
													{_formatDistance(
														record.distance,
														record.trip != null ? record.trip.distance : null
													)}
												</TableCell>
											)}
											{selectedOption === 'detail' && (
												<TableCell align="left">
													{isMetricUser ? convertFeetToMeters(record.accuracy) : record.accuracy}
												</TableCell>
											)}
											{selectedOption === 'detail' && hasForm && (
												<TableCell
													align="left"
													style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}
												>
													{isShowFormNameEmpty(record) ? (
														''
													) : record.form ? (
														record.form
													) : module.dispatchRecord4Map ? (
														title
													) : (
														<LoadingButton
															size="small"
															variant="outlined"
															color="inherit"
															loading={record.formLoading}
															sx={{ textTransform: 'none', lineHeight: '1.3em' }}
															onClick={() => handleGetFormName(record)}
														>
															{t('map.button.getFormName')}
														</LoadingButton>
													)}
												</TableCell>
											)}
											{selectedOption === 'detail' && hasRecord && (
												<TableCell
													align="left"
													style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}
												>
													{isShowRecordNameEmpty(record) ? (
														''
													) : record.recordName ? (
														record.recordName
													) : (
														<LoadingButton
															size="small"
															variant="outlined"
															color="inherit"
															loading={record.recordLoading}
															sx={{ textTransform: 'none', lineHeight: '1.3em' }}
															onClick={() => handleGetRecordName(record)}
														>
															{t('map.button.get')}
														</LoadingButton>
													)}
												</TableCell>
											)}
											{selectedOption === 'detail' && (
												<TableCell
													align="left"
													style={{ whiteSpace: 'nowrap', paddingTop: 0, paddingBottom: 0 }}
												>
													{record.address ? (
														record.address
													) : (
														<LoadingButton
															size="small"
															variant="outlined"
															color="inherit"
															loading={record.addressLoading}
															sx={{ textTransform: 'none', lineHeight: '1.3em' }}
															onClick={() => handleGetAddress(record)}
														>
															{t('map.button.get')}
														</LoadingButton>
													)}
												</TableCell>
											)}
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	)
}

export default DoformsMapGrid
