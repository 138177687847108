import {
	useContext,
	useMemo,
	useState,
	useRef,
	useEffect,
	useReducer,
	useLayoutEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import { Alert, LoadingButton } from '@mui/lab'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Button,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { loadAllColumns } from 'components/data/dataServices'

import useGetPathName from 'utils/hooks/useGetPathName'
import { callRecordsStorage } from '../../../utils/functions/helpers'

import { VIEW } from '../../../constants'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'

import { createView, updateView } from '../dataServices'
import LoadingSpinner from 'custom-components/LoadingSpinner'

import { checkAdminRole } from 'utils/functions/helpers'
import { ChooseAFormCollapse } from './CreatViewComponents/collapses/ChooseAFormCollapse'
import { SelectTheFieldsCollapse } from './CreatViewComponents/collapses/SelectTheFieldsCollapse'
import { PrimaryFilterCollapse } from './CreatViewComponents/collapses/PrimaryFilterCollapse'
import { AdditionalFiltersCollapse } from './CreatViewComponents/collapses/AdditionalFiltersCollapse'
import { SortTheDataCollapse } from './CreatViewComponents/collapses/SortTheDataCollapse'
import { GroupTheDataCollapse } from './CreatViewComponents/collapses/GroupTheDataCollapse'
import { AddCalculationsCollapse } from './CreatViewComponents/collapses/AddCalculationsCollapse'
// import { ApplyAStyleCollapse } from './CreatViewComponents/collapses/ApplyAStyleCollapse'
import { ColorPickerCollapse } from './CreatViewComponents/collapses/ColorPickerCollapse'
import { ChartCollapse } from './CreatViewComponents/collapses/ChartCollapse'
//import { SaveTheViewCollapse } from './CreatViewComponents/collapses/SaveTheViewCollapse'
import { SaveTheViewDialog } from './CreatViewComponents/dialogs/SaveTheViewDialog'

import { settingDataReducer } from './CreatViewComponents/ViewDialogUtils'
import { sleep } from '../../../utils/functions/helpers'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
	headingLabel: {
		display: 'flex',
		paddingLeft: '10px',
		minHeight: '32px',
	},
	buttonGroup: {
		'& .MuiToggleButtonGroup-grouped': {
			border: 0,
			textTransform: 'none',
			'&.Mui-disabled': {
				border: 0,
			},
		},
	},
	listBody: {
		maxHeight: '500px',
		overflowY: 'auto',
	},
	footer: {
		minHeight: '52px',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	body: {
		backgroundColor: '#fff',
		borderRadius: '5px',
		height: '95%',
		maxHeight: '700px',
		overflowY: 'auto',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	row: {
		width: '100%',
		height: 'auto',
		borderBottom: '1px solid #a8a8a8',
	},
	colLeft: {
		display: 'inline-block',
		width: '30%',
		textAlign: 'left',
		fontWeight: '500',
		paddingLeft: '10px',
		paddingTop: '10px',
		lineHeight: '30px',
	},
	colRight: {
		display: 'inline-block',
		width: '65%',
		textAlign: 'left',
		fontWeight: '500',
		color: '#b9b9b9',
	},
	collapseIcon: (props) => ({
		fontSize: 12,
		transform: 'rotate(270deg)',
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	contain: {
		display: 'none',
		marginLeft: '30%',
	},
	addNew: {
		height: '50px',
		width: '60px',
	},
}))

// function getDefaultSaveType(tab) {
// 	switch (tab) {
// 		case VIEW.VIEW_TAB_FORMS:
// 		case VIEW.VIEW_TAB_LOOKUPS:
// 			return VIEW.VIEW_TYPE_MYVIEW
// 		case VIEW.VIEW_TAB_VIEWS:
// 			return VIEW.VIEW_SAVE_TYPE_ADD
// 		default:
// 			return VIEW.VIEW_SAVE_TYPE_ADD
// 	}
// }

const DoformsDataSaveViewDialog = (props) => {
	const [t] = useTranslation('common')
	const firstPathName = useGetPathName()
	const dispatch = useDispatch()

	const { environment: environmentRedux } = useSelector((state) => state)

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const {
		clientFilter,
		hasManageRightsParam,
		environmentParam,
		ownersParam,
		gridRows,
		gridColumnsParam,
		viewData,
		queryView,
		recordsLoading,
		tab,
		aggregationModel,
		rowGroupingModel,
		sortModel,
		colorConditions: externalBgColorConditions,
		// isLoading,
		records,
		isAddNew,
		additionSaveCallBack,
		columns,
	} = props

	const [saveSettings, saveSettingDispatch] = useReducer(settingDataReducer, {
		primaryFilter: [],
		additionalFilter: [],
		sortData: [],
		groupTheData: [],
		calculations: [],
		chart: {},
		colorConditions: {},
		saveView: {},
	})
	// const [saveAsChoice, setSaveAsChoice] = useState(null)
	const [progressSave, setProgressSave] = useState(false)

	// selected project form
	const [projectFormInfo, setProjectFormInfo] = useState({})

	// fields collapse
	const [allColumns, setAllColumns] = useState([])
	const [allColumnsLoading, setAllColumnsLoading] = useState(false)
	const [listActiveFields, setListActiveFields] = useState([])
	const [listActiveFieldsOptions, setListActiveFieldsOptions] = useState([])
	const [isOpenDialog, setIsOpenDialog] = useState(false)

	const [initFilter, setInitFilter] = useState([])
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		if (isEmpty(clientFilter) || !Array.isArray(clientFilter)) return
		setInitFilter([...clientFilter])
	}, [clientFilter])

	const environment = useMemo(
		() => (environmentParam ? { ...environmentParam } : environmentRedux),
		[environmentParam, environmentRedux]
	)

	const resetData = (resetProjectFormInfo = false) => {
		if (resetProjectFormInfo) {
			setProjectFormInfo({})
		}

		setListActiveFields([])
	}

	const currentColumns = useMemo(() => {
		return (isEmpty(columns) ? viewData?.columns : columns) || []
	}, [viewData?.columns, columns])

	useEffect(() => {
		// add new mode
		//const defaultSaveType = getDefaultSaveType(tab)
		//setSaveType(defaultSaveType)
		if (isAddNew || isEmpty(viewData)) return

		// edit mode
		const projectKey = viewData.projectKey
		const formKey = viewData.formKey
		let project = environment.projects.find((item) => item.key === projectKey)
		if (!project && environment.lookups) {
			if (environment.lookups.length > 0) {
				project = environment.lookups?.find((item) => item.key === projectKey)
			} else {
				if (environment.lookups.key === projectKey) {
					project = environment.lookups
				}
			}
		}
		const projectName = project?.name || ''
		const form = environment.forms.find((item) => item.key === formKey)
		const formName = form?.name || ''
		const activeFields = currentColumns ? currentColumns.map((curItem) => curItem.name) : []
		setProjectFormInfo({
			projectKey,
			projectName,
			formKey,
			formName,
		})
		setListActiveFields(activeFields)
		//setViewName(viewData?.name)
		//setOwnerKey(viewData?.ownerKey)
	}, [viewData, environment, currentColumns])

	// fetching all columns by select project form
	useEffect(() => {
		if (!projectFormInfo || !projectFormInfo.formKey) return
		setAllColumnsLoading(true)
		loadAllColumns(projectFormInfo.formKey, environment.apiToken)
			.then((response) => {
				setAllColumns([...response.data])
			})
			.catch((err) => {
				setAllColumns([])
			})
			.finally(() => setAllColumnsLoading(false))
	}, [projectFormInfo, environment.apiToken])

	useLayoutEffect(() => {
		if (isEmpty(allColumns) || isEmpty(listActiveFields)) return

		const columnByActiveFieldsOrder = []
		for (const field of listActiveFields) {
			const column = allColumns.find((col) => field === col.name)
			if (!column) continue

			columnByActiveFieldsOrder.push(column)
		}
		setListActiveFieldsOptions(columnByActiveFieldsOrder)
	}, [allColumns, listActiveFields])

	const [expandedAccordion, setExpandedAccordion] = useState('')

	const userFinishAction = useRef(0)
	// const gridColumns = useMemo(
	// 	() => (gridColumnsParam ? [...gridColumnsParam] : []),
	// 	[gridColumnsParam]
	// )

	const hasManageRightsRedux = useMemo(() => {
		if (!environment) return []

		return checkAdminRole(environment?.userCurrent) || false
	}, [environment])
	// const [hasManageRights, setHasManageRights] = useState(hasManageRightsParam ?
	// 	hasManageRightsParam : hasManageRightsRedux
	// )
	let hasManageRights = hasManageRightsParam ? hasManageRightsParam : hasManageRightsRedux

	const [owners, setOwners] = useState(ownersParam ? ownersParam : environment.owners)
	/*const [saveType, setSaveType] = useState('')
	const [viewName, setViewName] = useState('')
	const [ownerKey, setOwnerKey] = useState('')
	*/
	// const [saveTypeAddUpdate, setSaveTypeAddUpdate] = useState(VIEW.VIEW_SAVE_TYPE_ADD)

	const handleClose = () => {
		// if (!userFinishAction.current) {
		// 	return
		// }
		// resetData();
		dispatch({
			type: isAddNew ? ENV_ACTIONS.SHOW_ADD_VIEW : ENV_ACTIONS.SHOW_EDIT_VIEW,
			payload: false,
		})
	}

	const handleCancel = () => {
		dispatch({
			type: isAddNew ? ENV_ACTIONS.SHOW_ADD_VIEW : ENV_ACTIONS.SHOW_EDIT_VIEW,
			payload: false,
		})
	}

	function getViewColumnsWithFilter(viewColumns, calculations, groupTheData) {
		if (isEmpty(viewColumns)) return []

		if (isEmpty(calculations) && isEmpty(groupTheData)) {
			return viewColumns
		}

		return viewColumns.map((column) => {
			if (groupTheData?.includes(column.name)) {
				column['group'] = column.name
			}

			const calculation = calculations?.find((cal) => cal.field === column.name)
			if (!calculation) return column

			column['aggregate'] = calculation.calType
			return column
		})
	}

	function validateColorConditions(colorConditions) {
		if (isEmpty(colorConditions)) return true

		// Validate data
		for (let key in colorConditions) {
			if (colorConditions.hasOwnProperty(key)) {
				if (key === 'primaryColumn') {
					const primaryColumnName = colorConditions[key]
					if (!primaryColumnName) {
						toast.error(
							`${t('common:dis.colorSettings')}: ${t('common:dis.pleaseFillInformation')}`
						)
						return false
					}
					continue
				}

				const array = colorConditions[key]
				for (let i = 0; i < array.length; i++) {
					const item = array[i]
					if (!item.type || !item.operator) {
						toast.error(
							`${t('common:dis.colorSettings')}: ${t('common:dis.pleaseFillInformation')}`
						)
						return false
					}
				}
			}
		}

		return true
	}

	const handleSaveApply = (event, saveViewSettings) => {
		setErrorMessage('')
		if (!saveViewSettings) {
			toast.error(`${t('common:view.saveTheView')}: ${t('common:dis.pleaseFillInformation')}`)
			return
		}
		event.preventDefault()
		//setProgressSave(true)
		const {
			primaryFilter,
			additionalFilter,
			sortData,
			groupTheData,
			calculations,
			colorConditions,
			chart,
			saveView,
		} = saveSettings

		const isValidColorConditions = validateColorConditions(colorConditions)
		if (!isValidColorConditions) {
			//setProgressSave(false)
			return
		}

		let tmpColums = []
		//initColumns
		let initColumnsGrid = columns || []
		for (const fields of listActiveFields) {
			let column = initColumnsGrid.find((item) => item.name === fields)
			if (!column) {
				column = allColumns.find((item) => item.name === fields)
			}
			if (!column) continue

			tmpColums.push(column)
		}

		const viewColumnsWithFilter = getViewColumnsWithFilter(tmpColums, calculations, groupTheData)
		let queries = queryView?.queries?.map((item, index) => ({
			fields: [...viewColumnsWithFilter],
			kind: viewData.queries.find((query) => query.type === item.type).kind,
			...item,
		}))
		if (queries?.length > 0 && queries[0].filter && queries[0].filter.conditions && primaryFilter) {
			queries[0].filter.conditions = [...primaryFilter]
			queries[1].filter.conditions = [...primaryFilter]
		}

		//convert sort
		//API: [{ column: 1, order: "DESC" }, { column: 2, order: "ASC" }]
		//MUI Grid : [{field:"columnName",sort:"desc"},...]
		let sorts = sortData
			.map((item) => {
				const index = listActiveFieldsOptions.findIndex((col) => col.name === item.field)
				if (index < 0) return null
				return {
					column: index,
					order: item?.sort.toUpperCase(),
				}
			})
			.filter((item) => !!item)

		const filters = []
		if (!isEmpty(additionalFilter)) {
			filters.push(...additionalFilter)
		}

		let tempViewData = {
			...viewData,
			begin: queryView?.begin,
			columns: viewColumnsWithFilter,
			queries: queries ? [...queries] : [],
			count: queryView?.count,
			millis: queryView?.millis,
			more: queryView?.more,
			sorts: [...sorts],
			chart: chart,
			clientFilter: JSON.stringify(filters),
		}

		if (VIEW.VIEW_TYPE_EVERYONE === saveViewSettings.saveType) {
			// ownerType = "DEFAULT"
			// everyone = true
			tempViewData = {
				...tempViewData,
				everyone: true,
			}
		} else if (VIEW.VIEW_TYPE_MYVIEW === saveViewSettings.saveType) {
			// ownerType = "DEFAULT"
		} else if (VIEW.VIEW_TYPE_VIEWTAB === saveViewSettings.saveType) {
			const owner = owners.find((owner) => owner.key == saveViewSettings.ownerKey)
			tempViewData.ownerType = owner.type
			tempViewData.ownerKey = owner.key
			tempViewData.name = saveViewSettings.viewName
		}

		if (!isEmpty(colorConditions)) {
			tempViewData.colorConditions = JSON.stringify(colorConditions)
		} else {
			tempViewData.colorConditions = ''
		}

		updateView(tempViewData, environment.apiToken)
			.then((response) => {
				if (tab === VIEW.VIEW_TAB_LOOKUPS) {
					dispatch({
						type: LOOKUPS_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if ([VIEW.VIEW_TAB_VIEWS, VIEW.TAB_TILE_BUILDER].includes(tab)) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if (tab === VIEW.VIEW_TAB_DEVICES) {
					dispatch({
						type: DEVICES_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if (tab === 'activity') {
					dispatch({
						type: ACTIVITY_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else {
					dispatch({
						type: FORM_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})

					dispatch({
						type: FORM_ACTIONS.VIEW_DATA,
						payload: tempViewData,
					})
				}

				userFinishAction.current = true
			})
			.catch((err) => {
				if (!err.response.data) {
					setErrorMessage(err)
					return
				}
				setErrorMessage('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				// waiting sever to update view
				sleep(3000)
					.then((result) => {
						if (tab === 'views') {
							dispatch({
								type: VIEWS_ACTIONS.VIEW_REFRESH,
								payload: true,
							})

							dispatch({
								type: VIEWS_ACTIONS.VIEW_DATA,
								payload: tempViewData,
							})
						} else if (tab === VIEW.VIEW_TAB_LOOKUPS) {
							dispatch({
								type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
								payload: true,
							})

							dispatch({
								type: LOOKUPS_ACTIONS.VIEW_DATA,
								payload: tempViewData,
							})
						}
						if (additionSaveCallBack) {
							additionSaveCallBack()
						}
					})
					.finally(() => {
						handleClose()
					})
			})
	}

	const handleOpenSaveDialog = (event) => {
		setErrorMessage('')
		setIsOpenDialog(true)
	}

	const handleSaveAsApply = (event, saveViewSettings) => {
		setErrorMessage('')

		if (!saveViewSettings) {
			toast.error(`${t('common:view.saveTheView')}: ${t('common:dis.pleaseFillInformation')}`)
			return
		}
		event.preventDefault()
		const {
			primaryFilter,
			additionalFilter,
			sortData,
			groupTheData,
			calculations,
			colorConditions,
			chart,
			saveView,
		} = saveSettings

		const isValidColorConditions = validateColorConditions(colorConditions)
		if (!isValidColorConditions) return
		let tmpColums = []
		//initColumns
		let initColumnsGrid = columns || []
		for (const fields of listActiveFields) {
			let column = initColumnsGrid.find((item) => item.name === fields)
			if (!column) {
				column = allColumns.find((item) => item.name === fields)
			}
			if (!column) continue

			tmpColums.push(column)
		}
		const viewColumnsWithFilter = getViewColumnsWithFilter(tmpColums, calculations, groupTheData)

		let queries = queryView?.queries?.map((item, index) => ({
			fields: [...viewColumnsWithFilter],
			kind: viewData.queries.find((query) => query.type === item.type).kind,
			...item,
		}))
		if (!queries && primaryFilter) {
			const query = {
				kind: projectFormInfo.formKey,
				type: 'SUBMISSION',
				fields: [...tmpColums],
				filter: {
					conditions: [],
				},
			}
			queries = [
				{ ...query },
				{
					...query,
					kind: 'dispatch' + projectFormInfo.formKey,
					type: 'DISPATCH',
				},
			]
		}

		if (queries?.length > 0 && queries[0].filter && queries[0].filter.conditions && primaryFilter) {
			queries[0].filter.conditions = [...primaryFilter]
			queries[1].filter.conditions = [...primaryFilter]
		}

		//convert sort
		//API: [{ column: 1, order: "DESC" }, { column: 2, order: "ASC" }]
		//MUI Grid : [{field:"columnName",sort:"desc"},...]
		let sorts = sortData.map((item) => ({
			column: listActiveFields.indexOf(item.field),
			order: item?.sort.toUpperCase(),
		}))

		let ownerType = ''
		let everyone = false
		if (VIEW.VIEW_TYPE_EVERYONE === saveViewSettings.saveType) {
			ownerType = 'DEFAULT'
			everyone = true
		} else if (VIEW.VIEW_TYPE_MYVIEW === saveViewSettings.saveType) {
			ownerType = 'DEFAULT'
		} else if (
			VIEW.VIEW_SAVE_TYPE_ADD === saveViewSettings.saveType ||
			VIEW.VIEW_SAVE_TYPE_UPDATE === saveViewSettings.saveType
		) {
			ownerType = owners.find((owner) => owner.key === saveViewSettings.ownerKey).type
		}

		let tempViewData = {
			...viewData,
			// columns: [...viewColumnsWithFilter],
			queries: queries,
			more: queryView?.more,
			//sorts: [],
			// ownerKey: owner.key,
			// ownerType: owner.type,
			//name: saveAsInput,
			//clientFilter: JSON.stringify(clientFilter),

			projectKey: projectFormInfo?.projectKey,
			formKey: projectFormInfo?.formKey,
			columns: viewColumnsWithFilter,
			// queries: [...viewDataObj?.primaryFilter],
			clientFilter: JSON.stringify(additionalFilter),
			sorts: sorts,
			chart: chart,
			//sorts: [{ column: 1, order: "DESC" }, { column: 2, order: "ASC" }],
			name: saveViewSettings.viewName,
			//ownerKey: "ag9zfm15ZG9mb3Jtcy1ocmRyEQsSBFVzZXIYgICk2ojFyQoM",
			ownerKey: saveViewSettings.ownerKey,
			// ownerType: "USER",
			ownerType,
		}
		if (isAddNew) {
			tempViewData.type = 'RECORD'
			tempViewData.schema = 5
			// tempViewData.createDate = "2024-01-18T16:09:10.824Z"
			// tempViewData.limit = 200
		}
		if (!isEmpty(colorConditions)) {
			tempViewData.colorConditions = JSON.stringify(colorConditions)
		}
		if (everyone) {
			tempViewData.everyone = true
		}
		delete tempViewData.more
		delete tempViewData.count

		createView(tempViewData, environment.apiToken)
			.then((response) => {
				// waiting sever to create new view
				sleep(10000)
					.then((result) => {
						if (tab === VIEW.VIEW_TAB_LOOKUPS) {
							dispatch({
								type: LOOKUPS_ACTIONS.VIEW_CREATE,
								payload: { ...tempViewData, key: response?.data?.key },
							})
						} else if ([VIEW.VIEW_TAB_VIEWS, VIEW.TAB_TILE_BUILDER].includes(tab)) {
							dispatch({
								type: VIEWS_ACTIONS.VIEW_CREATE,
								payload: { ...tempViewData, key: response?.data?.key },
							})
						} else if (tab === VIEW.VIEW_TAB_DEVICES) {
							dispatch({
								type: DEVICES_ACTIONS.VIEW_CREATE,
								payload: { ...tempViewData, key: response?.data?.key },
							})
						} else if (tab === 'activity') {
							dispatch({
								type: ACTIVITY_ACTIONS.VIEW_CREATE,
								payload: { ...tempViewData, key: response?.data?.key },
							})
						} else {
							dispatch({
								type: FORM_ACTIONS.VIEW_CREATE,
								payload: { ...tempViewData, key: response?.data?.key },
							})
						}

						userFinishAction.current = true
						if (additionSaveCallBack) {
							additionSaveCallBack()
						}
					})
					.finally(() => {
						handleClose()
						//setProgressSave(false)
					})
			})
			.catch((err) => {
				//setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setErrorMessage('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			})
	}

	return (
		<div className={isAddNew && classes.addNew}>
			<Dialog
				target={'form-save-view'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isAddNew ? environment.showAddView : environment.showEditView}
				closeAfterTransition
				sx={{
					'& .MuiDialog-paper': {
						width: '55% !important',
						maxWidth: '100% !important',
					},
					'& .MuiDataGrid-overlayWrapper': {
						minHeight: '100px',
					},
					'& .MuiDialogTitle-root': {
						padding: '16px 24px 0 !important',
					},
				}}
				onClose={handleClose}
			>
				<DialogTitle>
					<Typography
						id="transition-modal-title"
						className={classes.headingLabel}
						variant="h6"
						align="left"
					>
						{isAddNew === true ? t('common:formsData.newView') : t('common:formsData.editView')}
					</Typography>
				</DialogTitle>
				<DialogContent>
					{environment?.isLoadingProjects ? (
						// for load project form only
						<LoadingSpinner />
					) : (
						<div>
							<ChooseAFormCollapse
								projectFormInfo={projectFormInfo}
								setProjectFormInfo={setProjectFormInfo}
								resetViewData={resetData}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<SelectTheFieldsCollapse
								environment={environment}
								allColumns={allColumns}
								allColumnsLoading={allColumnsLoading}
								listActiveFields={listActiveFields}
								viewDataColumns={currentColumns || []}
								setListActiveFields={setListActiveFields}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<PrimaryFilterCollapse
								viewData={viewData}
								environment={environment}
								tab={tab}
								query={viewData?.queries}
								isAddNew={isAddNew}
								queryView={queryView}
								allColumns={allColumns}
								allColumnsLoading={allColumnsLoading}
								listActiveFields={listActiveFields}
								saveSettingDispatch={saveSettingDispatch}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<AdditionalFiltersCollapse
								clientFilter={clientFilter}
								allColumns={allColumns}
								allColumnsLoading={allColumnsLoading}
								listActiveFields={listActiveFields}
								listActiveFieldsOptions={listActiveFieldsOptions}
								saveSettingDispatch={saveSettingDispatch}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<SortTheDataCollapse
								viewData={viewData}
								sortModel={sortModel}
								allColumns={allColumns}
								listActiveFields={listActiveFields}
								listActiveFieldsOptions={listActiveFieldsOptions}
								saveSettingDispatch={saveSettingDispatch}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<GroupTheDataCollapse
								rowGroupingModel={rowGroupingModel}
								saveSettingDispatch={saveSettingDispatch}
								listActiveFields={listActiveFields}
								listActiveFieldsOptions={listActiveFieldsOptions}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<AddCalculationsCollapse
								allColumns={allColumns}
								aggregationModel={aggregationModel}
								saveSettingDispatch={saveSettingDispatch}
								listActiveFieldsOptions={listActiveFieldsOptions}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<ColorPickerCollapse
								listActiveFieldsOptions={listActiveFieldsOptions}
								colorConditions={externalBgColorConditions}
								saveSettingDispatch={saveSettingDispatch}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							<ChartCollapse
								isAddNew={isAddNew}
								viewData={viewData}
								records={records}
								columns={allColumns}
								tab={tab}
								gridRows={gridRows}
								recordsLoading={recordsLoading}
								listActiveFields={listActiveFields}
								listActiveFieldsOptions={listActiveFieldsOptions}
								environment={environment}
								saveSettingDispatch={saveSettingDispatch}
								expandedAccordion={expandedAccordion}
								setExpandedAccordion={setExpandedAccordion}
							/>
							{isOpenDialog && (
								<SaveTheViewDialog
									tab={tab}
									owners={owners}
									hasManageRights={hasManageRights}
									expandedAccordion={expandedAccordion}
									setExpandedAccordion={setExpandedAccordion}
									projectFormInfo={projectFormInfo}
									isAddNew={isAddNew}
									listActiveFields={listActiveFields}
									setIsOpenDialog={setIsOpenDialog}
									viewData={viewData}
									handleSaveApply={handleSaveApply}
									handleSaveAsApply={handleSaveAsApply}
									errorMessage={errorMessage}
								/>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel} className={classes.icon}>
						{t('common:misc.cancel')}
					</Button>
					<LoadingButton
						className={classes.icon}
						loading={progressSave}
						style={{
							minWidth: '80px',
						}}
						disabled={
							recordsLoading ||
							(listActiveFields !== null && listActiveFields.length === 0) ||
							listActiveFields === null
						}
						loadingPosition="start"
						onClick={handleOpenSaveDialog}
					>
						{t('common:misc.save')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default DoformsDataSaveViewDialog
